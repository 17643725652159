/** @jsx jsx */
import React, { useContext } from 'react'
import { Flex, jsx, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { PDPContext } from '../Page/PDPContext'
import ProductAddToCartButton from './Button'
import AddToCartProductSelector from './ProductSelector'
import AddToCartOptionSelector from './OptionSelector'
import SubscriptionChooser from '~/components/Subscription/Chooser'
import { fadeIn } from '~/utils/PDP'
import { useExperiment } from '~/hooks/utils/use-experiment'

const AddToCartSection = ({
  mounted,
  setCurrentValue,
  currentValue,
  onOpenModal,
  isPasta,
}) => {
  const {
    currentVariant,
    product,
    quantity: selectedQuantity,
    isLoading,
    outOfStock,
    soldOut,
    promoDiscountPrice,
    discountPercentage: promoDiscountPercentage,
  } = useContext(PDPContext)

  const ABTestSubscriptionToggle = useExperiment('CEyyLGfvT4axJ4ud61O6nQ')

  const { subscription, oosNote, productNote } = product || {}
  const { sku, comingSoon, price, regularPrice } = currentVariant || {}

  const packSize = ~~currentVariant?.optionValues
    ?.filter(
      val =>
        val?.option_type?.length && val?.option_type[0]?.slug.includes('size'),
    )
    .map(v => v.presentation)
    .find(() => true)

  const perBoxPrice = !product.isGoods ? price / packSize : null

  const translate = useTranslate()
  return (
    <Flex
      sx={{
        position: 'relative',
        zIndex: 1,
        justifySelf: 'end',
        flexDirection: 'column',
        width: '100%',
        maxWidth: ABTestSubscriptionToggle === 0 ? 320 : 402,
        minWidth: 275,
        margin: ['auto', null, 'initial'],
        opacity: mounted ? 1 : 0,
        transition: fadeIn(2500),
      }}
    >
      <div
        id="ATC-scroll-target"
        sx={{ position: 'relative', top: [-30, -60] }}
      />

      <AddToCartProductSelector />
      <AddToCartOptionSelector />
      {!product.isGoods &&
      !product.subscription &&
      currentVariant?.optionValues ? (
        <Text sx={{ color: 'secondary', pt: '10px' }}>
          {'$' + perBoxPrice.toFixed(2)} per box
        </Text>
      ) : null}
      {subscription ? (
        <SubscriptionChooser
          isLoading={isLoading}
          onClick={outOfStock && onOpenModal}
          outOfStock={outOfStock}
          setCurrentValue={setCurrentValue}
          currentValue={currentValue}
          perBoxPrice={perBoxPrice}
          subscription={subscription}
          soldOut={soldOut}
          comingSoon={comingSoon}
          regularPrice={regularPrice}
          price={price}
          sku={sku}
          quantity={selectedQuantity}
          slug={product.slug}
          isPasta={isPasta}
        />
      ) : (
        <>
          <Flex>
            {promoDiscountPrice && (
              <Text
                sx={{
                  fontSize: '14px',
                  mt: '0.5rem',
                  fontFamily: 'heading',
                  color: 'override.altButtonBackground',
                }}
              >
                Limited time offer {promoDiscountPercentage}% off.
              </Text>
            )}
          </Flex>
          <Flex
            sx={{
              display: 'inline-flex',

              py: 3,
            }}
          >
            <ProductAddToCartButton
              onClick={(outOfStock || comingSoon) && onOpenModal}
              isLoading={isLoading}
              comingSoon={comingSoon}
              disabled={soldOut}
              soldOut={soldOut}
              outOfStock={outOfStock}
              price={promoDiscountPrice ?? price}
              quantity={selectedQuantity}
              regularPrice={regularPrice}
              sku={sku}
              isPasta={isPasta}
            />
          </Flex>
        </>
      )}
      {!soldOut && (outOfStock || comingSoon) && oosNote && (
        <Text
          as="p"
          sx={{
            width: '100%',
            color: 'secondary',
            border: '#ffdd00 solid 2px',
            borderColor: 'secondary', //override
            borderRadius: 8,
            textAlign: 'center',
            fontFamily: 'Sofia-Regular',
            fontSize: 14,
            lineHeight: '18px',
            py: '7px',
            px: 20,
          }}
        >
          {oosNote}
        </Text>
      )}
      {ABTestSubscriptionToggle === 0 && !product.isGoods && (
        <Text
          as="p"
          sx={{
            marginTop: '16px',
            padding: '15px',
            pt: 0,
            fontSize: 0,
            color: 'secondary',
            fontFamily: 'monospace',
            textAlign: 'center',
          }}
        >
          {productNote ?? translate('product.guaranteed')}
        </Text>
      )}
    </Flex>
  )
}

export default AddToCartSection
