/** @jsx jsx */
import { jsx } from 'theme-ui'
import { utils } from '@chordcommerce/gatsby-theme-autonomy'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import ProductButton from '~/components/Product/ProductButton'
import { localeFromPathname } from '~/utils/intl/localePrefix'

const SubscribeButton = ({
  comingSoon = false,
  price,
  onClick,
  regularPrice,
  apiError,
  isLoading,
  soldOut,
  quantity,
  subscription,
  isDisabledByLocaleLimitations,
  isPasta,
  ...props
}) => {
  const location = useLocation()
  const amountPrefix =
    localeFromPathname(location.pathname).code === 'en-US' ? '' : 'US'
  const { toUsdCurrency } = utils
  const discountPrice = price * (1 - subscription.discountPercentage / 100)

  return (
    <ProductButton
      {...{
        amountPrefix,
        apiError,
        isLoading,
        comingSoon,
        discountPrice,
        isDisabledByLocaleLimitations,
        price,
        quantity,
        regularPrice,
        soldOut,
        onClick,
        toUsdCurrency,
        isPasta,
        ...props,
      }}
    />
  )
}

SubscribeButton.propTypes = {
  comingSoon: PropTypes.bool,
  disabled: PropTypes.bool,
  price: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  regularPrice: PropTypes.number,
  sku: PropTypes.string.isRequired,
  subscription: PropTypes.shape({}),
  interval: PropTypes.shape({}),
}

export default SubscribeButton
