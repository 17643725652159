/** @jsx jsx */

import React, { useContext } from 'react'
import { Modal } from 'react-responsive-modal'
import { jsx, useThemeUI } from 'theme-ui'

import Heading from '~/components/IntlComponents/Heading'

import Text from '~/components/IntlComponents/Text'
import { PDPContext } from '../Page/PDPContext'

import ProductOutOfStock from '../OutOfStock'

const WaitlistModal = ({ open, onCloseModal, currentVariant, product }) => {
  const {
    currentVariant: contextCurrentVariant,
    product: { waitlistPopup: contextWaitlistPopup },
  } = useContext(PDPContext)

  const variant = currentVariant || contextCurrentVariant
  const popup = product ? product.waitlistPopup : contextWaitlistPopup

  const {
    theme: { rawColors },
  } = useThemeUI()

  const waitlistBg = rawColors.override
    ? rawColors.override.waitlistBg
    : rawColors.modes['default-product'].waitlistBg
  const waitlistColor = rawColors.override
    ? rawColors.override.waitlistColor
    : rawColors.modes['default-product'].waitlistColor

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      styles={{
        modal: {
          background: waitlistBg,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: waitlistColor,
          color: waitlistColor,
          borderRadius: 8,
        },
        closeIcon: {
          fill: waitlistColor,
        },
      }}
      center
    >
      <div
        sx={{
          width: '100%',
          maxWidth: 582,
          position: 'relative',
          background: 'transparent',
          color: 'overrides.waitlistColor',
          button: {
            background: 'transparent',
            color: 'overrides.waitlistColor',
            '&:hover': {
              background: 'overrides.waitlistColor',
              color: 'overrides.waitlistBg',
            },
          },
        }}
      >
        {popup?.heading ? (
          <Heading variant="text.h5"> {popup.heading}</Heading>
        ) : null}
        {popup?.body ? (
          <Text
            dangerouslySetInnerHTML={{
              __html: popup.body.childMarkdownRemark.html,
            }}
          />
        ) : null}
      </div>
      <ProductOutOfStock sku={variant.sku} />
    </Modal>
  )
}

export default WaitlistModal
