/** @jsx jsx */
import { forwardRef } from 'react'
import { jsx, Label, Box, Radio, Flex } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'

const RadioOption = forwardRef(
  (
    {
      selected,
      presentation,
      RightComponent = null,
      BottomComponent = null,
      onChange,
      onClick,
      name,
      value,
      forwardSx,
      textStyle,
      id,
      tabIndex,
      ...props
    },
    ref,
  ) => {
    return (
      <Label
        htmlFor={id}
        sx={{
          textTransform: 'capitalize',
          cursor: 'pointer',
          ...forwardSx,
        }}
        tabIndex={tabIndex}
      >
        <Radio
          id={id}
          checked={selected}
          onChange={onChange}
          className="subscribeSelector"
          name={name}
          value={value}
          ref={ref}
          onClick={onClick}
          tabIndex={tabIndex}
          sx={{ ...forwardSx }}
          {...props}
        />

        <Box sx={{ width: '100%', fontFamily: 'Sofia' }}>
          <Flex sx={{ justifyContent: 'space-between', width: '100%' }}>
            <Text
              as="p"
              fontWeight={700}
              sx={{
                fontFamily: selected ? 'heading' : 'body',
                ...textStyle,
              }}
            >
              {presentation}
            </Text>
            {RightComponent}
          </Flex>
          {BottomComponent}
        </Box>
      </Label>
    )
  },
)

export default RadioOption
