/** @jsx jsx */

import { jsx } from 'theme-ui'

const StyledWrapper = ({ children, forwardSx = {} }) => {
  return (
    <div
      sx={{
        ...forwardSx,
      }}
    >
      {children}
    </div>
  )
}

export default StyledWrapper
