/** @jsx jsx */
import React, { useCallback, useEffect } from 'react'

import { useRef, useState } from 'react'
import { jsx, Flex } from 'theme-ui'
import Heading from '~/components/IntlComponents/Heading'

export const IngredientsAccordion = ({ children, title, refresh }) => {
  const [open, setOpen] = useState(false)
  const accordionHead = useRef(null)
  const accordionBody = useRef(null)
  const isMounted = useRef(false)

  const height = useCallback(
    () =>
      open
        ? accordionHead.current?.clientHeight +
          accordionBody.current?.clientHeight
        : accordionHead.current?.clientHeight,

    [open],
  )

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      return
    }

    setOpen(false)
    const clear = setTimeout(() => {
      setOpen(true)
    }, 300)

    return () => clearTimeout(clear)
  }, [refresh])

  return (
    <div
      sx={{
        maxWidth: '1216px',
        width: '100%',
        overflow: !open && 'hidden',
        backgroundColor: 'override.ingredientsBg',
        color: 'override.ingredientsText',
        height: height,
        transition: 'height 0.3s ease-in-out',
        borderRadius: '8px',
        mx: '23px',
        mb: '25px',
      }}
    >
      <Flex
        ref={accordionHead}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          cursor: 'pointer',
          padding: ['22px', '32px'],
        }}
        onClick={() => setOpen(!open)}
      >
        <Heading
          variant="text.eyebrow.uppercase"
          sx={{ fontSize: ['20px', '32px'] }}
        >
          {title}
        </Heading>

        <div
          sx={{
            position: 'relative',
            width: ['24px', '32px'],
            height: '100%',
            transform: !open && 'rotate(90deg)',
            transition: 'transform 0.2s',
          }}
        >
          <span
            sx={{
              width: ['24px', '32px'],
              height: ['3px', '4px'],
              backgroundColor: 'override.ingredientsText',
              display: 'inline-block',
              position: 'absolute',
              inset: 0,
              margin: 'auto',
            }}
          />
          <span
            sx={{
              width: ['24px', '32px'],
              height: ['3px', '4px'],
              backgroundColor: 'override.ingredientsText',
              display: 'inline-block',
              position: 'absolute',
              inset: 0,
              margin: 'auto',
              transform: !open && 'rotate(90deg)',
              transition: 'transform 0.2s',
            }}
          />
        </div>
      </Flex>

      <div
        ref={accordionBody}
        sx={{
          px: '32px',
          pb: '32px',
          opacity: open ? '1' : '0',
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        {children}
      </div>
    </div>
  )
}
