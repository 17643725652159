/** @jsx jsx */

import React, { useContext, useMemo } from 'react'
import { jsx, Box } from 'theme-ui'
import VortexBackground from '../FuturisticComponents/VortexBackground'
import { PDPContext } from '../Page/PDPContext'
import ImgLoader from '~/components/Generic/ImgLoader'
import Penne from '~/assets/images/icons/PenneOutline.svg'
import PenneV2 from '~/assets/images/icons/PenneOutlineV2.svg'
import Curve from '~/assets/images/icons/CurveOutline.svg'
import Loopdy from '~/assets/images/icons/LoopdyOutline.svg'

const ImagesSection = ({ mounted }) => {
  const { currentVariant, product } = useContext(PDPContext)
  const { isGoods, slug, colorTheme } = product ?? {}
  const colorThemeSlug = colorTheme?.slug

  const NoodleIcon = useMemo(() => {
    // The vortexBackground will grab the array or single svg item returned to it, it will choose one at random to display for every item.

    if (/dry-pasta/i.test(colorThemeSlug)) {
      if (/curve/i.test(slug)) return Curve
      if (/loopdy/i.test(slug)) return Loopdy
      if (/penne/i.test(slug)) return [Penne, Penne, PenneV2] // increase chances of normal penne spawning
      return [Penne, PenneV2, Loopdy, Loopdy, Curve, Curve] // all equal chances
    }
  }, [colorThemeSlug, slug])
  const fadeIn = delay => `opacity 300ms ${delay}ms`

  const sixpackGift = slug === '6-mac-gift-pack'
  return (
    <>
      {/* Desktop images */}

      <Box
        sx={{
          position: 'absolute',
          top: '0%',
          marginTop: /dry-pasta/i.test(colorThemeSlug) ? ['0', '7%'] : 0,
          transform: 'translateX(-50%)',
          left: '50%',
          width: '100%',
          maxWidth: sixpackGift
            ? 'min(1040px, 50vw)'
            : /dry-pasta/i.test(colorThemeSlug) || /pasta-pack/i.test(slug)
            ? '1040px'
            : '1440px',
          zIndex: 0,
          display: ['none', null, 'initial'],
          opacity: mounted ? 1 : 0,
          transition: fadeIn(2000),
        }}
      >
        {/dry-pasta/i.test(colorThemeSlug) && (
          <div
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <VortexBackground noodleSVG={NoodleIcon} noodleNumber={6} />
          </div>
        )}
        <ImgLoader
          slug={product && !!product.slug ? product.slug : ''}
          image={
            isGoods
              ? currentVariant.mainImage
              : !!product
              ? product.overheadProductImage
              : null // Can add skeleton images
          }
          alt={
            isGoods
              ? currentVariant.mainImage.title
              : !!product &&
                !!product.overheadProductImage &&
                !!product.overheadProductImage.title
              ? product.overheadProductImage.title
              : null
          }
        />
      </Box>
      {/* Mobile Images */}

      <Box
        sx={{
          mt: [0, '5rem'],
          display: ['initial', null, 'none'],
          opacity: mounted ? 1 : 0,
          transition: fadeIn(2000),
          position: 'relative',
        }}
      >
        {/dry-pasta/i.test(colorThemeSlug) && (
          <div
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <VortexBackground noodleSVG={NoodleIcon} noodleNumber={3} />
          </div>
        )}
        <ImgLoader
          image={
            isGoods
              ? currentVariant.mainImage
              : !!product && !!product.overheadProductImage
              ? product.overheadProductImage
              : null
          }
          forwardSx={{
            minWidth: ['768px', 'unset'],
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          alt={
            isGoods
              ? currentVariant.mainImage.title
              : !!product &&
                !!product.overheadProductImage &&
                !!product.overheadProductImage.title
              ? product.overheadProductImage.title
              : null
          }
        />
      </Box>
    </>
  )
}

export default ImagesSection
