import { useLayoutEffect, useState } from 'react'
/**
 * Enter textRef React element reference and maxWidth in pixels.
 * returns new fontsize number in pixels
 * @param {{textRef: import('react').Ref, maxWidth: number}} param0
 * @returns {number}
 */
export default function useResizeTextToLimit({ textRef, maxWidth }) {
  const [newFontSize, setNewFontSize] = useState(0)
  useLayoutEffect(() => {
    if (!textRef.current) return

    const returnNewFontSize = () => {
      const width = textRef.current.offsetWidth
      const currentFontSize = Number.parseFloat(
        getComputedStyle(textRef.current).fontSize,
      )
      const widthLimit = maxWidth
      const newFontSize = (currentFontSize * widthLimit) / width

      if (width > widthLimit) {
        setNewFontSize(newFontSize)
      }
    }
    returnNewFontSize()
    window.addEventListener('resize', returnNewFontSize)
    return () => {
      window.removeEventListener('resize', returnNewFontSize)
    }
  }, [textRef])
  return newFontSize
}
