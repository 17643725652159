/** @jsx jsx */
import React from 'react'
import { Button, Flex, jsx } from 'theme-ui'
import Spinner from '~/components/Generic/Spinner'

const AddToCartButtonWrapper = ({
  children,
  shortText,
  isLoading,
  isPasta,
  ...props
}) => {
  const { sku } = props
  return (
    <Button
      p={0}
      type="submit"
      sx={{
        //color: 'override.altButtonColor',
        color: isPasta ? '#F652AC !important' : 'override.altButtonColor',
        backgroundColor: isPasta
          ? '#FFDD00 !important'
          : 'override.altButtonBackground',
        width: '100%',
        display: 'block',
        textTransform: 'capitalize',
        '&:hover': {
          color: isPasta
            ? '#FFDD00 !important'
            : 'override.altButtonBackground',
          backgroundColor: isPasta
            ? '#FF5600 !important'
            : 'override.altButtonColor',
        },
      }}
      {...props}
    >
      <Flex sx={{ height: '100%' }}>
        <Flex
          sx={{
            alignItems: 'center',
            flexGrow: 1,
            height: '100%',
            justifyContent: 'center',
            padding: shortText ? 0 : ['0 16px', null, '0 32px'],
          }}
        >
          {isLoading && (
            <Spinner data-testid="spinner" size="15" color="inherit" />
          )}
          {!isLoading && <>{children}</>}
        </Flex>
      </Flex>
    </Button>
  )
}

export default AddToCartButtonWrapper
