import React from 'react'
import { Box } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'
import Spotlight from '~/assets/images/icons/Spotlight.svg'
import { makeFloatAnimation } from '~/utils/animations'

const HolographicNutrition = ({ icon, title }) => {
  const floatAnimObj = (distance, duration) => {
    const float = makeFloatAnimation(distance)
    return {
      animation: `-${Math.random()}s jerk 100ms linear infinite, ${float} ${duration}ms infinite ease-in-out`,
    }
  }
  return (
    <Box>
      <Box
        sx={{
          height: '218px',
          width: ['109px', '218px'],
          position: 'relative',
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Box
            sx={{
              '@keyframes jump': {
                '0%,90.5%, 92.5%, 96%': { transform: 'none' },
                '91%, 92%, 97%,100%': {
                  transform: 'skew(-30deg)',
                },
              },

              animation: 'jump 3s linear infinite',
            }}
          >
            <Box
              sx={{
                '@keyframes jerk': {
                  '0%': {
                    transform: 'translate(-2px)',
                    opacity: '.8',
                  },
                  '100%': {
                    transform: 'translate(0px)',
                    opacity: '1',
                  },
                },
                filter:
                  'blur(1px) drop-shadow(0 2px 1px magenta) drop-shadow(0 -2px 1px cyan) drop-shadow(0px 3px 1px green)',
                ...floatAnimObj(5, 2000 + Math.random() * 1000),
              }}
            >
              {icon}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '0%',
            left: '50%',
            transform: 'translate(-50%, 0%)',
          }}
        >
          <Box
            sx={{
              animation: 'jerk 1s linear infinite alternate',
              mixBlendMode: ' hard-light',
              position: 'absolute',
              bottom: '-14px',
              left: '-93px',
            }}
          >
            <Spotlight />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '0px',
              width: '36px',
              height: '9px',
              left: '50%',
              transform: 'translate(-50%)',
              borderRadius: '100%',
              background: '#FFDD00',
            }}
          ></Box>
        </Box>
      </Box>
      <Text
        as="p"
        sx={{
          mt: '1rem',
          position: 'relative',
          bottom: 0,
          textAlign: 'center',
          left: 0,
        }}
      >
        {title}
      </Text>
    </Box>
  )
}

export default HolographicNutrition
