/** @jsx jsx */
import { useContext } from 'react'
import { Flex, jsx } from 'theme-ui'

import Text from '~/components/IntlComponents/Text/index.jsx'
import ProductOptions from '~/components/Product/Options'
import ProductQuantitySelector from '~/components/Product/QuantitySelector'
import { PDPContext } from '../Page/PDPContext'

const AddToCartOptionSelector = () => {
  const {
    currentVariant,
    setCurrentVariant,
    product,
    setQuantity: setSelectedQuantity,
  } = useContext(PDPContext)

  const { variants, optionTypes, giftCard } = product || {}

  // get all the available choices from the variants
  const allVariantOptions = (variants || []).flatMap(v => v.optionValues)

  // filter through possible duplicates
  const uniqueVariantOptions = allVariantOptions.filter(
    (option, index, self) =>
      index === self.findIndex(opt => opt?.slug === option?.slug),
  )

  // build a list of option types, restricting choices to those available via our variants
  const allowedOptionValues = optionTypes?.map(
    ({ name, slug, presentation }) => ({
      name,
      optionValues: uniqueVariantOptions.filter(
        option =>
          option?.option_type?.length && option?.option_type[0]?.slug === slug,
      ),
      presentation,
      slug,
    }),
  )

  const handleOptionValueChange = option => {
    // First, we will save current variant options slugs & values in an object, to later filter out the ones that are not matching
    const currentValues = currentVariant?.optionValues?.reduce(
      (acc, optionValue) => {
        const slug = optionValue?.option_type?.length
          ? optionValue?.option_type[0]?.slug
          : ''
        acc[slug] = optionValue.name
        return acc
      },
      {},
    )

    // Next, we'll set the newly selected option in that object
    const slug = option?.option_type?.length ? option?.option_type[0]?.slug : ''
    currentValues[slug] = option?.name

    // Now, we'll loop through the product's variants to find ones that match _all_ current selected values
    const matchingVariants = (product?.variants || []).filter(variant => {
      const optionValues = variant?.optionValues

      // If there are missing option values, then the variant is not matching
      const missingOption = optionValues.find(opt => {
        const optionSlug = opt?.option_type?.length
          ? opt?.option_type[0]?.slug
          : ''
        return (
          !currentValues[optionSlug] || currentValues[optionSlug] !== opt.name
        )
      })
      return missingOption ? false : true
    })

    if (matchingVariants.length) {
      setCurrentVariant(matchingVariants[0])
    }
  }

  return (
    <Flex
      sx={{
        flexDirection: giftCard ? ['column'] : ['row'],
        textAlign: 'left',
        justifyContent: 'space-between',
        marginTop: 30,
        flexWrap: 'wrap',
      }}
    >
      {optionTypes && (
        <ProductOptions
          isGoods={!!product ? product.isGoods : false}
          allOptions={allowedOptionValues}
          currentValues={currentVariant.optionValues}
          handleChange={option => handleOptionValueChange(option)}
          variants={variants}
          product={product}
        />
      )}
      <Flex
        sx={{
          flexDirection: ['column'],
          justifyContent: 'center',
          textAlign: 'left',
          width: giftCard ? ['35%'] : 'auto',
          mt: giftCard ? '12px' : 'auto',
        }}
      >
        <Text sx={{ color: 'secondary' }} variant="text.eyebrow">
          Quantity
        </Text>
        <ProductQuantitySelector
          sx={{
            color: 'override.detailMain',
            borderColor: 'override.detailMain',
            backgroundColor: 'override.detailBackground',
          }}
          handleChange={value => setSelectedQuantity(parseInt(value))}
        />
      </Flex>
    </Flex>
  )
}

export default AddToCartOptionSelector
