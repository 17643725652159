import axios from 'axios'
const API_URL = process.env.GATSBY_ACS_API_URL || ''

const _cache = {};

const getAvailabilitySku = async (sku, cacheTime = 60, cacheErrors = false) => {

  const now = (new Date()).getTime();
  const cacheLimit = now - cacheTime * 1000
  if (_cache && _cache[sku] && _cache[sku].time && _cache[sku].time > cacheLimit) {
    return _cache[sku].data
  }

  const baseURL = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL
  const query = `${baseURL}/api/variants/${sku}/stock_availabilities`
  const data = (await axios.get(query)).data
  
  if (!data.error || cacheErrors) {
    _cache[sku] = {
      time: now,
      data
    }
  }

  return data
};

const getAvailabilitySkus = (skus, cacheTime = 60, cacheErrors = false) => {
  return Promise.all(skus.map(sku => getAvailabilitySku(sku, cacheTime, cacheErrors)))
}

export {
  getAvailabilitySku,
  getAvailabilitySkus
}