/** @jsx jsx */
import React, { useContext } from 'react'
import { jsx, Grid, Button, Spinner, Flex, Text } from 'theme-ui'
import { Global } from '@emotion/react'
import { useWindowScroll } from 'react-use'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { utils } from '@chordcommerce/gatsby-theme-autonomy'
import { navigate } from 'gatsby'
import { PDPContext } from '../PDPContext'
import useMatchMedia from '../../../../hooks/utils/use-match-media'
import useVariantAvailability from '~/hooks/components/use-variant-availability'
import RadioOption from '~/components/Subscription/RadioOption'

export default function StickyAddToCart({
  product,
  currentVariant,
  onOpenModal,
}) {
  const hideRadios = useMatchMedia('(max-width: 1140px)')
  const hideImage = useMatchMedia('(max-width: 450px)')
  const { toUsdCurrency } = utils
  const { cart, isFetching } = useCart()
  const {
    value,
    onChange,
    register,
    setDefaultInterval,
    resetSubscription,
    productAdded,
    priceAfterDiscount,
    quantity,
    isLoading,
    soldOut,
    promoDiscountPrice,
  } = useContext(PDPContext)
  const shouldShowContainer = useWindowScroll().y > 200
  const translate = useTranslate()
  const defaultVariant = product.variants[0]
  const variant = currentVariant ?? defaultVariant
  const { isGoods } = product
  const { sku, price } = variant
  const { isAvailable } = useVariantAvailability({ sku })

  const getButtonLabel = () => {
    if (productAdded) {
      return translate('product.continue_checkout')
    }

    if (!isAvailable || soldOut) {
      const priceValue =
        value === 'subscribe-and-save'
          ? priceAfterDiscount * quantity
          : price * quantity
      if (soldOut)
        return `${translate('product.sold_out')} | ${toUsdCurrency(priceValue)}`
      return `${translate('product.join_waitlist')} | ${toUsdCurrency(
        priceValue,
      )}`
    }

    if (value === 'subscribe-and-save') {
      return `${translate('product.add_to_cart')} | ${toUsdCurrency(
        priceAfterDiscount * quantity,
      )}`
    }

    if (promoDiscountPrice) {
      return `${translate('product.add_to_cart')} | ${toUsdCurrency(
        promoDiscountPrice * quantity,
      )}`
    }

    return `${translate('product.add_to_cart')} | ${toUsdCurrency(
      price * quantity,
    )}`
  }

  const goToCheckout = () => {
    navigate('/cart')
  }

  const addToCartImage = product?.stickAddToCartImage
    ? product?.stickAddToCartImage
    : currentVariant?.mainImage

  const productAddedToCart = (
    <Flex
      sx={{
        textAlign: 'center',
        display: hideRadios ? 'none' : 'flex',
        color: 'override.stickyATCColor',
      }}
    >
      <Text
        as="p"
        sx={{
          color: 'inherit',
          fontSize: '20px',
          lineHeight: '20px',
          fontFamily: 'heading',
        }}
      >
        {translate('product.added_to_cart')}
      </Text>
    </Flex>
  )

  const subscribeAndSave = !isGoods && (
    <Flex
      aria-labelledby="subscription-label"
      sx={{
        width: '100%',
        alignItems: 'center',
        height: '100%',
        display: hideRadios ? 'none' : 'flex',
        color: 'override.stickyATCColor',
        gap: '2rem',
      }}
    >
      <RadioOption
        forwardSx={{
          flexShrink: 0,
          width: 'fit-content',
          whiteSpace: 'nowrap',
        }}
        id="subscribe-and-save-radio"
        selected={value === 'subscribe-and-save'}
        onChange={e => onChange(e)}
        name="subscription-type"
        value="subscribe-and-save"
        {...register('subscription-type')}
        aria-checked={value === 'subscribe-and-save'}
        onClick={e => {
          onChange(e)
          setDefaultInterval()
        }}
        tabIndex="0"
        presentation={product.subscription?.presentation}
      />
      <RadioOption
        forwardSx={{
          flexShrink: 0,
          width: 'fit-content',
          whiteSpace: 'nowrap',
        }}
        id="subscribe-and-save-radio-one-time"
        selected={value === 'one-time-purchase'}
        onChange={e => onChange(e)}
        name="subscription-type"
        value="one-time-purchase"
        {...register('subscription-type')}
        aria-checked={value === 'one-time-purchase'}
        onClick={e => {
          onChange(e)
          resetSubscription()
        }}
        tabIndex="0"
        presentation={translate('subscriptions.one_time_purchase')}
      />
    </Flex>
  )

  return (
    <>
      <Global
        styles={{
          '#attentive_creative': {
            bottom: shouldShowContainer ? '82px !important' : '16px !important',
            transition: 'all 1000ms ease-in-out',
          },
        }}
      />

      <Grid
        sx={{
          '--height': '70px',
          backgroundColor: 'override.stickyATCBg',
          color: 'override.stickyATCBorder',
          borderTopColor: 'override.stickyATCBorder',
          borderTop: '1px solid',
          position: 'fixed',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          px: '10px',
          bottom: 0,
          transform: shouldShowContainer ? 'translateY(0)' : 'translateY(100%)',
          transition: '0.15s ease',
          height: 'var(--height)',
          zIndex: 900,
          gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(3, 1fr)'],
        }}
      >
        {/* Img + name section */}
        <Flex sx={{ alignItems: 'center' }}>
          <GatsbyImage
            image={addToCartImage.gatsbyImageData}
            alt={addToCartImage.title}
            sx={{
              img: { objectFit: 'contain !important' },
              width: '60px',
              minWidth: '50px',
              height: 70,
              mr: '12px',
            }}
          />
          <Text
            sx={{
              whiteSpace: 'wrap',
              fontFamily: 'heading',
              color: 'override.stickyATCColor',
              fontSize: [16, 20],
            }}
          >
            {product.name}
          </Text>
        </Flex>
        {/* subscription section */}
        <div
          sx={{
            display: ['none', 'block'],
            justifySelf: 'center',
          }}
        >
          {!productAdded && product?.subscription && subscribeAndSave}
          {productAdded && productAddedToCart}
        </div>

        {/* Add To cart section */}

        <Button
          sx={{
            textTransform: 'capitalize',
            justifySelf: 'end',
            alignSelf: 'center',
            fontFamily: 'heading',
            fontSize: [hideImage ? '16px' : '18px', '20px'],
            lineHeight: '100%',
            textAlign: 'center',
            px: [hideRadios ? '8px' : '24px', '32px'],
            width: 'auto',
            minWidth: hideRadios && !hideImage ? '220px' : 'auto',
            color: productAdded ? 'override.stickyATCColor' : 'white',
            bg: productAdded ? '#fff' : 'override.stickyATCColor',
            borderColor: productAdded ? '#fff' : 'override.stickyATCColor',
            transition: 'background 0.2s ease-in-out, color 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: productAdded
                ? '#fff'
                : 'override.stickyATCColor',
              color: productAdded
                ? 'override.stickyATCColor'
                : 'override.stickyATCBg',
            },
          }}
          disabled={soldOut}
          onClick={
            productAdded
              ? goToCheckout
              : soldOut
              ? null
              : !isAvailable && onOpenModal
          }
        >
          {isFetching || isLoading ? (
            <Spinner color="white" size="32px" sx={{ width: '100%' }} />
          ) : (
            getButtonLabel()
          )}
        </Button>
      </Grid>
    </>
  )
}
