/** @jsx jsx */
import { jsx } from 'theme-ui'
import { utils } from '@chordcommerce/gatsby-theme-autonomy'
import { useLocation } from '@reach/router'
import ProductButton from '../ProductButton'
import { localeFromPathname } from '~/utils/intl/localePrefix'

const ProductAddToCartButton = ({
  comingSoon = false,
  isLoading,
  onClick,
  soldOut,
  outOfStock,
  price,
  quantity,
  regularPrice,
  shortText = false,
  apiError,
  isDisabledByLocaleLimitations,
  isPasta,
  ...props
}) => {
  const { toUsdCurrency } = utils
  const { pathname } = useLocation()
  const amountPrefix = localeFromPathname(pathname).code === 'en-US' ? '' : 'US'

  return (
    <ProductButton
      {...{
        amountPrefix,
        apiError,
        comingSoon,
        isLoading,
        isDisabledByLocaleLimitations,
        price,
        quantity,
        regularPrice,
        shortText,
        soldOut,
        outOfStock,
        toUsdCurrency,
        onClick,
        isPasta,
        ...props,
      }}
    />
  )
}

export default ProductAddToCartButton
