import axios from 'axios'

const API_URL = process.env.GATSBY_ACS_API_URL || ''
const baseURL = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL

const useCreateStockRequest = () => {
  const createStockRequest = ({ email, sku }) => {
    const url = `${baseURL}/api/stock_requests`
    const data = { stock_request: { email, sku } }
    return axios.post(url, data)
  }

  return { createStockRequest }
}

export default useCreateStockRequest
