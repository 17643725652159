/** @jsx jsx */

import { GatsbyImage } from 'gatsby-plugin-image'
import { Modal } from 'react-responsive-modal'
import { jsx } from 'theme-ui'
import React from 'react'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import useMatchMedia from '~/hooks/utils/use-match-media'
import Heading from '~/components/IntlComponents/Heading'
import Text from '~/components/IntlComponents/Text'

export const IngredientLabelModal = ({
  onCloseModal,
  ingredientNutritionLabels,
  openModal,
}) => {
  const translate = useTranslate()
  const isMobile = useMatchMedia('(max-width: 768px)')

  const ingJson = () => {
    try {
      return ingredientNutritionLabels[0]?.description
        ? JSON.parse(ingredientNutritionLabels[0]?.description)
        : ''
    } catch (e) {
      return ''
    }
  }
  const isJsonArray = Array.isArray(ingJson())
  const ingJsonArray = isJsonArray ? ingJson() : [ingJson()]

  return (
    <Modal
      styles={{
        modal: {
          maxWidth: isMobile ? '360px' : '886px',
          width: '100%',
          padding: 0,
          borderRadius: '24px',
          marginInline: isMobile ? '0px' : '1.2rem',
        },
      }}
      open={openModal}
      onClose={onCloseModal}
      center
      classNames="ingredientsModal"
    >
      <div
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundColor: 'primary',
          color: 'tertiary',
          textAlign: 'center',
          padding: '24px',
        }}
      >
        <Heading
          as="h3"
          sx={{
            fontSize: ['20px', '28px'],
            mb: '16px',
            maxWidth: '442px',
            mx: 'auto',
            color: 'secondary',
          }}
        >
          {translate('product.nutritional_modal_title')}
        </Heading>
        <Text
          sx={{
            display: 'inline-block',
            fontSize: ['16px', '18px'],
            mb: '20px',
            textTransform: 'uppercase',
            color: 'secondary',
          }}
        >
          {translate('product.nutritional_modal_subtitle')}
        </Text>

        {ingredientNutritionLabels?.length ? (
          <>
            <GatsbyImage
              alt={
                isMobile
                  ? ingredientNutritionLabels[1]?.title
                  : ingredientNutritionLabels[0]?.title
              }
              image={
                isMobile
                  ? ingredientNutritionLabels[1]?.gatsbyImageData
                  : ingredientNutritionLabels[0]?.gatsbyImageData
              }
              sx={{
                img: {
                  objectFit: 'contain !important',
                  objectPosition: 'top center !important',
                },
                width: '100%',
              }}
            />
            {ingJsonArray.map((j, index) => {
              return (
                <script
                  id={`ingredientNutritionJSON${index > 0 ? index + 1 : ''}`}
                  key={`ingredientNutritionJSON${index > 0 ? index + 1 : ''}`}
                >
                  {JSON.stringify(j)}
                </script>
              )
            })}
          </>
        ) : null}
      </div>
    </Modal>
  )
}
