/** @jsx jsx */
import { jsx } from 'theme-ui'
import useTriggerLocaleWarning from '~/utils/intl/context/hooks/use-trigger-locale-warning'

// Triggers the locale warning
const TriggerWarningAroundDisabledButtonsWrapper = ({
  children,
  disabledByLocaleLimitations,
  ...props
}) => {
  const { triggerShowWarning } = useTriggerLocaleWarning()
  const handleTriggerModal = () => {
    if (!disabledByLocaleLimitations) return
    triggerShowWarning()
  }
  return (
    <div
      onClick={handleTriggerModal}
      sx={{ cursor: disabledByLocaleLimitations && 'not-allowed' }}
      {...props}
    >
      <div sx={{ pointerEvents: disabledByLocaleLimitations && 'none' }}>
        {children}
      </div>
    </div>
  )
}

export default TriggerWarningAroundDisabledButtonsWrapper
