/** @jsx jsx */

import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Box, jsx } from 'theme-ui'
import useMatchMedia from '~/hooks/utils/use-match-media'
import { makeFloatAnimation } from '~/utils/animations'
import PropTypes from 'prop-types'
const getRandomIntInclusiveBetween = (min, max) => {
  let minInt = Math.ceil(min)
  let maxInt = Math.floor(max)
  return Math.floor(Math.random() * (maxInt - minInt + 1) + min)
}

const VortexBackground = ({ noodleSVG, noodleNumber = 0 }) => {
  const [clientLoad, setClientLoad] = useState(false)

  useEffect(() => {
    setClientLoad(true) // noodles render poorly staticly
  }, [])

  const getRandomNoodle = useCallback(
    index => {
      // Noodle can be an array of elements, in that case, choose one at random to distribute
      if (Array.isArray(noodleSVG)) {
        const randomNoodle =
          noodleSVG?.[getRandomIntInclusiveBetween(0, noodleSVG.length - 1)]
        if (randomNoodle)
          return React.createElement(randomNoodle, {
            key: `${index}-noodle`,
          })
      }
      return React.createElement(noodleSVG, {
        key: `${index}-noodle`,
      })
    },
    [noodleSVG],
  )

  const noodleList = useMemo(
    () =>
      noodleSVG
        ? Array(noodleNumber)
            .fill(0)
            .map((_, i) => getRandomNoodle(i))
        : [],
    [],
  )

  const float = makeFloatAnimation(30)
  const isMobileViewport = useMatchMedia('(max-width: 768px)')
  return (
    <Box
      sx={{
        '--maxSize': ['450px', '100%'],
        height: ['90vw', '512px'],
        width: ['90vw', '512px'],
        minWidth: [0, '512px'],
        minHeight: [0, '512px'],
        maxWidth: 'var(--maxSize)',
        maxHeight: 'var(--maxSize)',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          '@keyframes flicker': {
            '0%, 68%, 73%, 77%, 100%': {
              fill: `rgba(0,0,0,0)`,
            },
            '70%, 72%, 75%,': {
              fill: '#FFDD00',
            },
          },
          width: '100%',
          height: '100%',
          animation: '5s linear infinite rotate',
          borderRadius: '100%',
          position: 'relative',
          isolation: 'isolate',
          background:
            'linear-gradient(black, black) padding-box, linear-gradient(to bottom, #F652AC, #FFDD00) border-box;',
          boxShadow: `0 -10px 30px 5px rgba(246, 82, 172, .5),
      -10px 0px 30px 5px rgba(246, 82, 172, .5),
      0 10px 30px 5px rgba(255, 221, 0, .5),
      10px 0px 30px 5px rgba(255, 221, 0, .5),
     inset  0 10px 30px 5px rgba(246, 82, 172, .5),
     inset  10px 0px 30px 5px rgba(246, 82, 172, .5),
      inset 0 -10px 30px 5px rgba(255, 221, 0, .5),
      inset  -10px 0px 30px 5px rgba(255, 221, 0, .5),
      inset 0 0px 100px 60px rgba(24, 29, 73, 1)`,
          mixBlendMode: 'dodge',
          border: '5px solid transparent;',
        }}
      />

      {clientLoad &&
        noodleList.map((item, i, arr) => {
          return (
            <Box
              key={`${item.key}-noodle`}
              sx={{
                position: 'absolute',
                left: ['calc(50% - 65px)'],
                top: ['calc(50% - 65px)', 'calc(50% - 35px)'],
                transform: `rotate(${i * (360 / arr.length)}deg)  translateX(${
                  isMobileViewport
                    ? 'min(45vw, calc(var(--maxSize) / 2))'
                    : '256px'
                })`,
                textAlign: 'center',
                color: 'secondary',
              }}
            >
              <Box
                sx={{
                  svg: {
                    size: isMobileViewport
                      ? i % 3 === 0
                        ? '50%'
                        : '100%'
                      : i % 2 === 0
                      ? '50%'
                      : '100%',
                  },
                  filter:
                    'drop-shadow(0px 0px 5px #FFDD00) drop-shadow(0px 0px 1px #FFDD00)',
                  animation: `flicker 3s linear infinite, ${float} 10s infinite`,
                  animationDelay: `${i % 0.15}s`,
                }}
              >
                <div
                  sx={{
                    transform: `rotate(${
                      -(i * (360 / arr.length)) +
                      getRandomIntInclusiveBetween(0, 360)
                    }deg)`,
                    overflow: 'visible',
                  }}
                >
                  {item}
                </div>
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}

VortexBackground.propTypes = {
  noodleNumber: PropTypes.number.isRequired,
  noodleSVG: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.arrayOf(PropTypes.elementType),
  ]).isRequired,
}

export default React.memo(VortexBackground)
