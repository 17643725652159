/** @jsx jsx */

import { Flex, Grid, jsx, useThemeUI } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import React from 'react'
import { CleanLabel } from './CleanLabel'
import StyledWrapper from '~/components/Generic/StyledWrapper'
import Heading from '~/components/IntlComponents/Heading'
import { SectionDivider } from '~/components/Nav/NavMenu/SectionDivider'

export const GetToKnow = ({ product, hideCleanLabel }) => {
  const translate = useTranslate()

  const {
    theme: { rawColors },
  } = useThemeUI()

  return (
    <>
      <Grid
        sx={{
          color: 'primary',
          justifyItems: 'center',
          backgroundColor: 'override.sectionBackground',
          paddingBottom: 80,
          position: 'relative',
        }}
      >
        {!hideCleanLabel && (
          <CleanLabel
            color="primary"
            forwardSx={{
              transform: 'translateY(-50px)',
            }}
          />
        )}

        <StyledWrapper>
          <Heading variant="text.h6" sx={{ textTransform: 'uppercase' }}>
            {translate('product.get_to_know')}
          </Heading>
        </StyledWrapper>

        <StyledWrapper>
          <Heading
            variant="text.h1"
            sx={{ textAlign: 'center', maxWidth: '20ch' }}
          >
            {product.name}
          </Heading>
        </StyledWrapper>
      </Grid>

      <div
        sx={{
          position: 'relative',
          zIndex: 15,
          height: ['450px', '100%'],
        }}
      >
        <div
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            transform: ' scaleY(-1)',
            zIndex: 15,
            display: 'block',
          }}
        >
          <SectionDivider
            forwardSx={{ my: '-1px' }}
            fill={rawColors.override?.sectionBackground}
            small
          />
        </div>

        {product.bubblesImage && (
          <div sx={{ display: ['flex', 'block'], height: '100%' }}>
            <GatsbyImage
              style={{ width: '100%', height: '100%' }}
              image={product.bubblesImage.gatsbyImageData}
              alt={product.bubblesImage.title}
            />
          </div>
        )}

        <ParallaxProvider>
          <div
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              fontSize: 38,
              color: 'secondary',
              zIndex: 25,
            }}
          >
            <Parallax
              y={[0, 18]}
              styleOuter={{ height: '100%' }}
              styleInner={{ height: '100%' }}
              sx={{
                width: '100%',
                maxWidth: 1440,
                height: '100%',
                position: 'relative',
                margin: 'auto',
              }}
            >
              {product?.bubbleCopy?.map((x, i) => (
                <Flex
                  key={i}
                  sx={{
                    position: 'absolute',
                    // dynamic keys
                    [i < 2 ? 'top' : 'bottom']: i * 3 + '%',
                    [i % 2 === 1 ? 'left' : 'right']: i + 2 + '%',
                    width: [122, 250],
                    height: [122, 250],
                    borderRadius: '50%',
                    backgroundColor: 'primary',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Heading
                    as="h4"
                    variant="text.h4"
                    sx={{
                      textTransform: 'none',
                      fontSize: ['16px !important', '32px !important'],
                      padding: '15px',
                    }}
                  >
                    {x}
                  </Heading>
                </Flex>
              ))}
            </Parallax>
          </div>
        </ParallaxProvider>

        <div
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
          }}
        >
          <SectionDivider
            forwardSx={{
              margin: '-1px',
            }}
            fill={rawColors.override?.sectionBackground}
            small
          />
        </div>
      </div>
    </>
  )
}
