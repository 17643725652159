/** @jsx jsx */

import { GatsbyImage } from 'gatsby-plugin-image'
import { Button, Grid, jsx } from 'theme-ui'
import { LinkWrapper } from './LinkWrapper'
import Text from '~/components/IntlComponents/Text'

function RecirculationThreeUp({ products = [], buttonStyle }) {
  return (
    <Grid
      columns={[1, 'repeat(3,minmax(0, 320px))']}
      gap={60}
      sx={{
        margin: 'auto',
        width: ['90%', 'fit-content'],
        justifyItems: ['center'],
      }}
    >
      {products?.map(relatedProduct => {
        if (!relatedProduct) return null
        return (
          <LinkWrapper
            key={relatedProduct.slug}
            title={relatedProduct.title}
            slug={relatedProduct.slug}
            contenttype={relatedProduct.__typename}
            sx={{
              maxWidth: 320,
              width: '100%',
            }}
          >
            <Grid
              sx={{
                maxWidth: 320,
                width: '100%',
              }}
              key={relatedProduct.slug}
            >
              <div
                sx={{
                  // padding: '1rem',
                  position: 'relative',
                  ' &::before': {
                    content: "''",
                    display: 'block',
                    paddingBottom: '100%',
                  },
                }}
              >
                <div
                  sx={{
                    overflow: 'hidden',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0%',
                    // left: '50%'
                    // transform: 'translate(-50%, -50%)'
                    borderRadius: '50%',
                    border: '#FFDD00 solid 2px',
                    '&:hover': {
                      '.gatsby-image-wrapper': {
                        overflow: 'visible !important',
                      },
                      img: {
                        transformOrigin: 'top',
                        transform: 'scale(1.1) translateY(-6%)',
                      },
                    },
                  }}
                >
                  {relatedProduct.relatedProductImageReference &&
                  relatedProduct.relatedProductImageReference
                    .gatsbyImageData ? (
                    <GatsbyImage
                      image={
                        relatedProduct.relatedProductImageReference
                          .gatsbyImageData
                      }
                      alt={relatedProduct.relatedProductImageReference.title}
                    />
                  ) : (
                    <GatsbyImage
                      image={relatedProduct.mainImage.gatsbyImageData}
                      alt={relatedProduct.mainImage.title}
                    />
                  )}
                </div>
              </div>

              <Button
                sx={{
                  width: '100%',
                  ...buttonStyle,
                }}
              >
                <Text>
                  {relatedProduct.__typename === 'ContentfulCollection'
                    ? 'Shop '
                    : null}
                  {relatedProduct.name}
                </Text>
              </Button>
            </Grid>
          </LinkWrapper>
        )
      })}
    </Grid>
  )
}
export default RecirculationThreeUp
