/** @jsx jsx */
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { Box, Flex, jsx, Grid, useThemeUI } from 'theme-ui'
import { PDPContext } from '../Page/PDPContext'
import StarRating from '../StarRating'
import AddToCartSection from '../AddToCartSection'
import ProductDetailsSubHeading from './SubHeading'
import ProductDetailsMainHeading from './MainHeading'
import ProductDetailsDescription from './Description'
import ImagesSection from '~/components/Product/ImagesSection'
import WaitlistModal from '~/components/Product/WaitlistModal'
import { getAvailabilitySkus } from '~/utils/get-availability-sku'

const ProductDetails = ({
  collectionName = null,
  setCurrentValue,
  currentValue,
  onOpenModal,
  onCloseModal,
  open,
  soldOut,
  ...props
}) => {
  const {
    currentVariant,
    setCurrentVariant,
    product,
    variantHasPromoDiscount,
  } = useContext(PDPContext)

  const {
    longDescription,
    variants,
    name,
    additionalProperties,
    giftCard,
    isGoods,
  } = product || {}

  const {
    theme: { rawColors },
  } = useThemeUI()

  const [hasLoadedVariantAvailability, setHasLoadedVariantAvailability] =
    useState(false)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [setMounted])

  useEffect(() => {
    // Some SKUs 12 packs are temp OOS so default to lower packs
    const higherPackNotDefault =
      ['cheddy-mac'].includes(product.slug) || variantHasPromoDiscount

    ;(async () => {
      if (hasLoadedVariantAvailability) {
        setCurrentVariant(currentVariant)
        return
      }

      const allVariantAvailabilities = await getAvailabilitySkus(
        variants.map(v => v.sku),
      )
      setHasLoadedVariantAvailability(true)

      let chosenVariant = variants[0]

      if (product && product.subscription) {
        for (let i = variants.length - 1; i >= 0; i--) {
          const variantAvailability = allVariantAvailabilities.find(
            v => v && v.sku === variants[i].sku,
          )
          if (
            variantAvailability &&
            variantAvailability.in_stock &&
            !higherPackNotDefault
          ) {
            chosenVariant = variants[i]
            break
          }
        }
      }
      setCurrentVariant(chosenVariant)
    })()
  }, [currentVariant])

  if (!product) {
    return null
  }

  return (
    <Grid
      gap={0}
      columns={[1, null, 3]}
      {...props}
      sx={{
        width: '100%',
        paddingTop: [0, null, 100],
        paddingBottom: 30,
        paddingX: [24, null, 0],
        position: 'relative',
      }}
    >
      <WaitlistModal open={open} onCloseModal={onCloseModal} />
      <ImagesSection mounted={mounted} />
      <Box
        sx={{
          mt: /dry-pasta/i.test(product.theme) && ['30px', 0],
          color: 'secondary',
          position: 'relative',
          zIndex: 1,
          textAlign: ['center', null, 'initial'],
        }}
      >
        <ProductDetailsSubHeading
          currentVariant={currentVariant}
          product={product}
          giftCard={giftCard}
          mounted={mounted}
        />
        <ProductDetailsMainHeading name={name} mounted={mounted} />
        <Flex
          sx={{
            alignItems: 'center',
            mt: '1.875rem',
            gap: '1.125rem',
            mx: ['auto', null, 0],
            width: 'fit-content',
          }}
        >
          {!isGoods && (
            <StarRating
              product={product}
              color={
                /pasta/i.test(product.theme)
                  ? 'secondary'
                  : rawColors?.override?.detailMain
              }
              showTotalReviews={false}
              showReadReviews={true}
            />
          )}
        </Flex>
        <ProductDetailsDescription
          longDescription={longDescription}
          mounted={mounted}
          additionalProperties={additionalProperties}
        />
      </Box>
      {/* Placeholder div to account for center grid being empty */}
      <div sx={{ display: ['none', null, 'initial'] }} />
      <AddToCartSection
        soldOut={soldOut}
        mounted={mounted}
        setCurrentValue={setCurrentValue}
        currentValue={currentValue}
        onOpenModal={onOpenModal}
        isPasta={product?.collection?.map(col => col.slug).includes('pasta')}
      />
    </Grid>
  )
}

ProductDetails.propTypes = {
  product: PropTypes.shape({
    comingSoon: PropTypes.bool,
    description: PropTypes.shape({}),
    longDescription: PropTypes.shape({}),
    ingredients: PropTypes.shape({}),
    name: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    relatedProducts: PropTypes.array,
    size: PropTypes.string,
    sku: PropTypes.string,
    quote: PropTypes.shape({}),
    collectionName: PropTypes.string,
  }),
}

export default ProductDetails
