/** @jsx jsx */
import PropTypes from 'prop-types'
import React from 'react'
import { Flex, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import AddToCartButtonWrapper from './AddToCartWrapper'
import Text from '~/components/IntlComponents/Text'
import TriggerWarningAroundDisabledButtonsWrapper from '~/components/IntlComponents/TriggerWarningAroundDisabledButtonsWrapper'

const ProductButton = ({
  amountPrefix,
  apiError,
  comingSoon,
  isLoading,
  isDisabledByLocaleLimitations,
  price,
  quantity,
  regularPrice,
  shortText,
  soldOut,
  outOfStock,
  toUsdCurrency,
  discountPrice,
  promoDiscountPrice,
  isPasta,
  ...props
}) => {
  const translate = useTranslate()

  const getPriceWithDiscountOrDefaultForProduct = () => {
    if (promoDiscountPrice) return toUsdCurrency(promoDiscountPrice * quantity)
    if (discountPrice) return toUsdCurrency(discountPrice * quantity)
    if (price !== regularPrice) return toUsdCurrency(price * quantity)

    return toUsdCurrency(price * quantity)
  }

  const renderPrice = () => {
    if (price === regularPrice)
      return (
        <Text sx={{ lineHeight: '1.5rem' }}>
          {price
            ? `${amountPrefix}${getPriceWithDiscountOrDefaultForProduct()}`
            : ''}
        </Text>
      )

    if (price !== regularPrice)
      return (
        <Flex
          sx={{
            height: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px 0',
          }}
        >
          <Text
            sx={{
              textDecoration: 'line-through',
              color: 'white',
              opacity: '.7',
              variant: 'text.formLabel',
              fontSize: ['1.1rem'],
              lineHeight: ['10px', null, '12px'],
              mr: '8px',
            }}
          >
            {regularPrice ? `${amountPrefix}${regularPrice * quantity}` : ''}
          </Text>
          <Text sx={{ lineHeight: '1.5rem' }}>
            {price
              ? `${amountPrefix}${getPriceWithDiscountOrDefaultForProduct()}`
              : ''}
          </Text>
        </Flex>
      )
  }

  const renderCorrectButton = () => {
    if (soldOut)
      return (
        <AddToCartButtonWrapper
          isPasta={isPasta}
          isLoading={isLoading}
          {...props}
        >
          <Text> {soldOut && translate('product.sold_out')}</Text>
          {price && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}
          {renderPrice()}
        </AddToCartButtonWrapper>
      )
    if (outOfStock)
      return (
        <AddToCartButtonWrapper
          isPasta={isPasta}
          isLoading={isLoading}
          {...props}
        >
          <Text> {outOfStock && translate('product.join_waitlist')}</Text>
          {price && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}
          {renderPrice()}
        </AddToCartButtonWrapper>
      )
    if (comingSoon)
      return (
        <AddToCartButtonWrapper
          isPasta={isPasta}
          isLoading={isLoading}
          {...props}
        >
          <Text> {comingSoon && translate('product.coming_soon')}</Text>
          {price && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}
          {renderPrice()}
        </AddToCartButtonWrapper>
      )
    return (
      <TriggerWarningAroundDisabledButtonsWrapper
        disabledByLocaleLimitations={isDisabledByLocaleLimitations}
      >
        <AddToCartButtonWrapper
          isPasta={isPasta}
          isVarietyPack={{}}
          isLoading={isLoading}
          disabled={isDisabledByLocaleLimitations}
          {...props}
        >
          <Text> {shortText ? 'Add' : translate('product.add_to_cart')}</Text>
          {price && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}
          {renderPrice()}
        </AddToCartButtonWrapper>
      </TriggerWarningAroundDisabledButtonsWrapper>
    )
  }

  return (
    <div sx={{ width: '100%' }}>
      {renderCorrectButton()}
      {apiError && (
        <Text color="error" variant="textLink" mt="1.5rem">
          {apiError.toString()}
        </Text>
      )}
    </div>
  )
}

ProductButton.propTypes = {
  comingSoon: PropTypes.bool,
  disabled: PropTypes.bool,
  price: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  regularPrice: PropTypes.number,
  sku: PropTypes.string.isRequired,
}

export default ProductButton
