/** @jsx jsx */

import { GatsbyImage } from 'gatsby-plugin-image'
import { useLayoutEffect, useRef, useState } from 'react'
import { Flex, jsx, Box } from 'theme-ui'
import HolographicNutrition from '../FuturisticComponents/HolographicNutrition'
import Heading from '~/components/IntlComponents/Heading'
import Text from '~/components/IntlComponents/Text'

const FuturisticNutritionSection = ({
  valuePropHeading,
  valuePropSubheading,
  globalAssetsReference,
}) => {
  const [textWidth, setTextWidth] = useState(0)
  const textRef = useRef()

  useLayoutEffect(() => {
    if (!textRef.current) return

    const resizeTextWidth = () => {
      setTextWidth(textRef.current.offsetWidth)
    }
    resizeTextWidth()
    window.addEventListener('resize', resizeTextWidth, { passive: true })
    return () => {
      window.removeEventListener('resize', resizeTextWidth, { passive: true })
    }
  }, [textRef])

  if (!valuePropHeading || !valuePropSubheading || !globalAssetsReference)
    return null

  return (
    <Box
      sx={{
        borderRadius: '60px',
        width: '100%',
        height: '100%',
      }}
    >
      <Heading
        sx={t => ({
          fontSize: ['40px', '55px'],
          textTransform: 'uppercase',
          // WebkitTextStroke: `2px ${t.colors.secondary}`,
          color: t.colors.secondary,
          position: 'relative',
          textAlign: 'center',
          verticalAlign: 'middle',

          height: '90px',
          // top: '-50%',
          ':before, :after': {
            content: '""',
            position: 'absolute',
            width: `calc(50% - ${textWidth / 2 + 20}px)`,
            height: '60px',
            flex: '1 1 0',
            bottom: 0,
            filter: `drop-shadow(0 0 4px ${t.colors.secondary})`,
          },
          ':before': {
            borderTop: `2px solid ${t.colors.secondary}`,
            borderLeft: `2px solid ${t.colors.secondary}`,

            borderRadius: '60px 0px 0px',
            left: 0,
          },
          ':after': {
            borderTop: `2px solid ${t.colors.secondary}`,
            borderRight: `2px solid ${t.colors.secondary}`,
            borderRadius: '0px 60px 0px',
            right: '-4px',
          },
        })}
      >
        <Text ref={textRef}>{valuePropHeading}</Text>
      </Heading>

      <Box
        sx={t => ({
          color: 'secondary',

          width: '100%',
          height: '100%',
          minHeight: '150px',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'top',
          position: 'relative',
          ':before': {
            filter: `drop-shadow(0 0 2px ${t.colors.secondary})`,
            content: '""',
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            borderTop: '0',
            borderBottom: '2px solid',
            borderLeft: '2px solid',
            borderRight: '2px solid',
            borderColor: 'secondary',
            borderRadius: '0px 0px 60px 60px',
          },
        })}
      >
        <Text
          as="p"
          sx={{
            px: '5em',
            mx: 'auto',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {valuePropSubheading}
        </Text>

        <Flex
          sx={{
            p: ['10px', '3em'],
            width: '100%',
            flexWrap: 'wrap',
            gap: '50px',
            justifyContent: 'center',
          }}
        >
          {globalAssetsReference?.valuePropImage?.map(item => (
            <div key={item.id} sx={{ flex: ['1 0', '1 0 30%;'] }}>
              <HolographicNutrition
                title={item.title}
                icon={
                  <GatsbyImage
                    alt={item.title}
                    sx={{
                      height: ['90px', '120px'],
                      width: ['90px', '120px'],
                      img: {
                        objectFit: 'contain !important',
                      },
                    }}
                    image={item.gatsbyImageData}
                  />
                }
              />
            </div>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default FuturisticNutritionSection
