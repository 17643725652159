/** @jsx jsx */

import { GatsbyImage } from 'gatsby-plugin-image'
import { Fragment } from 'react'
import { Grid, jsx } from 'theme-ui'
import FuturisticNutritionSection from '../FuturisticComponents/FuturisticNutritionSection'
import NutritionSection from '../NutritionSection'
import { Ingredients } from './Ingredients'
import { GetToKnow } from './GetToKnow'
import { TryNow } from './TryNow'
import { SectionDivider } from '~/components/Nav/NavMenu/SectionDivider'
import ReviewWidget from '~/components/Reviews/Widget/YotpoWidget'

export function ProductDisplaySections({
  product,
  rawColors,
  productIngredientRefObjectIndex,
  setIndex,
}) {
  const { ingredientSections, ingredientNutritionLabels, hideCleanLabel } =
    product

  return (
    <Fragment>
      {/* value prop */}

      <NutritionSection
        valuePropHeading={product.valuePropHeading}
        valuePropSubheading={product.valuePropSubheading}
        globalAssetsReference={product.globalAssetsReference}
      />

      {/* Ingredients Section */}
      <Ingredients
        product={product}
        setIndex={setIndex}
        ingredientSections={ingredientSections}
        ingredientNutritionLabels={ingredientNutritionLabels}
        productIngredientRefObjectIndex={productIngredientRefObjectIndex}
      />

      <Grid
        sx={{
          bottom: 0,
          width: '100%',
          transform: 'scaleY(-1)',
          backgroundColor: 'background',
        }}
      >
        <SectionDivider
          forwardSx={{ my: '-1px' }}
          fill={rawColors.override?.sectionBackground}
        />
      </Grid>

      {/* GetToKnow Section */}
      {product.bubbleCopy && product.bubblesImage && (
        <GetToKnow product={product} hideCleanLabel={hideCleanLabel} />
      )}

      <div
        sx={{
          height: ['100px', '200px'],
          background: rawColors.override?.sectionBackground,
        }}
      />

      {!!product.bowlDividerImage && (
        <div>
          <SectionDivider
            forwardSx={{ transform: 'translateY(-2px)' }}
            fill={rawColors.override?.sectionBackground}
          />
          <Grid
            sx={{
              maxWidth: 300,
              margin: 'auto',
              transform: 'translate(0,-50%)',
            }}
          >
            <GatsbyImage
              image={product.bowlDividerImage?.gatsbyImageData}
              alt={product.bowlDividerImage?.title}
            />
          </Grid>
        </div>
      )}

      {/* Yotpo Reviews */}
      <ReviewWidget product={product} />

      {/* Try now Sections*/}
      {product.pedestalImage && <TryNow product={product} />}
    </Fragment>
  )
}
