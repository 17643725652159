/** @jsx jsx */
import { useRef } from 'react'
import { jsx } from 'theme-ui'
import Heading from '~/components/IntlComponents/Heading'
import Text from '~/components/IntlComponents/Text'
import useResizeTextToLimit from '~/hooks/components/use-text-resize-to-limit'
import { flashIn } from '~/utils/PDP'
import { localeFromPathname } from '~/utils/intl/localePrefix'

const ProductDetailsMainHeading = ({ name, mounted }) => {
  const textRef = useRef()
  const newFontSize = useResizeTextToLimit({ textRef, maxWidth: 425 })
  let longestWord = name?.split(' ')?.[0]
  return (
    <Heading
      sx={{
        fontSize: newFontSize ? [null, `${newFontSize}px !important`] : null,
        textAlign: ['center', null, 'left'],
      }}
      as="h1"
      variant="h1"
    >
      {name &&
        name.split(' ').map((word, i) => {
          // find the longest word and add the ref on that to calculate the max font-size
          longestWord = word?.length > longestWord?.length ? word : longestWord
          return (
            <span
              ref={longestWord === word ? textRef : null}
              key={word}
              sx={{
                opacity: mounted ? 1 : 0,
                transition: flashIn((1000 / name.split(' ').length) * i + 1000),
              }}
            >
              {word}
              {i === name.length - 1 ? '' : ` `}
            </span>
          )
        })}
    </Heading>
  )
}

export default ProductDetailsMainHeading
