/** @jsx jsx */
import { jsx } from 'theme-ui'
import Heading from '~/components/IntlComponents/Text/index.jsx'
import { fadeIn } from '~/utils/PDP'

const ProductDetailsSubHeading = ({
  product,
  currentVariant,
  giftCard,
  mounted,
}) => {
  const isGoods = product?.productType?.slug === 'goods'
  return (
    <Heading
      as="h6"
      sx={{
        mt: ['.5rem', null, '.75rem'],
        mb: ['.5rem', null, '1.25rem'],
        textTransform: 'uppercase',
        fontSize: '0.875rem',
        height: '0.875rem',
        opacity: mounted ? 1 : 0,
        transition: fadeIn(2500),
      }}
    >
      {!!product && !isGoods && currentVariant?.optionValues
        ? currentVariant?.optionValues
            ?.filter(
              val =>
                val?.option_type?.length &&
                val.option_type[0]?.slug.includes('size'),
            )
            .map(v => v.presentation)
            .find(v => true)
        : null}{' '}
      {!!product && isGoods ? null : 'PACK'}
      {giftCard && 'DIGITAL'}
    </Heading>
  )
}

export default ProductDetailsSubHeading
