/** @jsx jsx */
import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { jsx } from 'theme-ui'

import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { PDPContext } from '../Page/PDPContext'
import Text from '~/components/IntlComponents/Text/index.jsx'
import ProductSelector from '~/components/Generic/ProductSelector'
import { toURL } from '~/utils/intl/localePrefix'
import useSelectorOptions from '~/hooks/components/use-selector-options'
import { productPathBuilder } from '~/utils/builder/index.cjs'

const AddToCartProductSelector = () => {
  const { product } = useContext(PDPContext)
  const translate = useTranslate()
  const selectorOptions = useSelectorOptions(product)

  if (!product) return null

  const handleChange = detail => {
    navigate(toURL(productPathBuilder(detail)))
  }

  const options = product.isGoods // food products have categories in selector
    ? selectorOptions?.map(option => ({
        name: option?.name,
        value: option?.slug,
        path: option?.path,
      }))
    : selectorOptions?.map(optionGroup => ({
        ...optionGroup,
        options: optionGroup?.options?.map(option => ({
          name: option?.name,
          value: option?.slug,
        })),
      }))

  if (!selectorOptions) return null

  return (
    <>
      <Text
        sx={{ color: 'secondary', marginBottom: 16 }}
        variant="text.eyebrow"
      >
        {!!product && product.isGoods
          ? translate('product.selector_pick')
          : translate('product.selector_flavor')}
      </Text>
      <ProductSelector
        sx={t => ({
          color: 'override.detailMain',
          borderColor: 'override.detailMain',
          background: t.colors.override?.detailBackground,
          fill: 'override.detailMain',
        })}
        handleChange={handleChange}
        defaultValue={product?.slug ?? ''}
        optionsHaveGroups={!product.isGoods}
        options={options}
      />
    </>
  )
}

export default AddToCartProductSelector
