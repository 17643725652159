/**
 * allows to do some overrides based on the pathname
 * useful for themes that don't completely match the pattern
 *
 * @param {string} pathname from useLocation()
 * @returns { {backgroundColor: string, color: string} }
 */
export const getButtonHoverStyles = pathname => {
  if (/shella-good|pasta|lucky-penne|loopdy-loos|curveballs/i.test(pathname))
    return {
      backgroundColor: 'secondary',
      color: null,
    }
  if (/elote/i.test(pathname))
    return {
      backgroundColor: '#ffdd00',
      color: 'secondary',
    }
  return {
    backgroundColor: 'secondary',
    color: 'override.altButtonColor',
  }
}
