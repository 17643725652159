/** @jsx jsx */
import { Fragment, useEffect } from 'react'
import { Flex, jsx } from 'theme-ui'
import React from 'react'
import Heading from '~/components/IntlComponents/Heading'

const ReviewWidget = ({ product }) => {
  useEffect(() => {
    if (typeof window.yotpo == 'object') {
      window.yotpo.initWidgets()
    }
  }, [product])

  return (
    <>
      <span
        sx={{
          color: 'secondary',
          textAlign: 'center',
          mb: '40px',
        }}
      >
        <Heading variant="text.h3">{product.reviewHeading}</Heading>
      </span>

      <Flex id="review-widget" sx={{ marginBottom: 50, paddingInline: '25px' }}>
        <div
          className="yotpo yotpo-main-widget"
          data-product-id={product.yotpoId}
          data-name={product.name}
          data-image-url={product.mainImage.gatsbyImageData.src}
          data-description={
            product.shortDescription ? product.shortDescription : ''
          }
          sx={{
            color: 'secondary',
            borderColor: 'secondary',
            maxWidth: 1440,
            '.yotpo.yotpo-lightbox.yotpo-lightbox-old': {
              zIndex: 'auto !important',
            },
            '.bottom-line-items': {
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
            },
            '.reviews-qa-labels-container': {
              margin: '0 !important',
              padding: '0 !important',
              position: 'static !important',
            },
            '.yotpo-header-element': {
              width: '100% !important',
              position: 'relative',
            },
            '.yotpo-user-title': {
              right: '0 !important',
              position: 'absolute',
            },
            '.yotpo-regular-box': {
              padding: '25px 0 !important',
              border: 'none !important',
              position: 'relative',
              '&:after': {
                pointerEvents: 'none',
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '1px',
                bg: 'currentcolor',
                opacity: 0.5,
                bottom: 0,
              },
            },
            '.sort-drop-down': {
              zIndex: 5,
            },
            '.yotpo-reviews.yotpo-active': {
              width: '100%',
            },
          }}
        />
      </Flex>
    </>
  )
}

export default ReviewWidget
