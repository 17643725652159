import { graphql, useStaticQuery } from 'gatsby'
import { productPathOverride } from '~/utils/builder/index.cjs'

export const useMacAndCheese = ({ locale, currentProductSlug }) => {
  // getting all of the mac and cheese products and packs to then return an array containing them
  const { macProducts, macPacks, dryPasta, dryPastaPacks } =
    useStaticQuery(graphql`
      query Products {
        macProducts: allContentfulProduct(
          filter: { productType: { slug: { eq: "mac-and-cheese" } } }
          sort: { order: DESC, fields: product___product___product___slug }
        ) {
          edges {
            node {
              collection {
                slug
                catalog {
                  slug
                }
              }
              name
              slug
              node_locale
              hidden
            }
          }
        }
        macPacks: allContentfulProduct(
          filter: { productType: { slug: { eq: "variety-pack" } } }
        ) {
          edges {
            node {
              collection {
                slug
                catalog {
                  slug
                }
              }
              name
              slug
              node_locale
              hidden
            }
          }
        }
        dryPasta: allContentfulProduct(
          filter: { productType: { slug: { eq: "pasta" } } }
        ) {
          edges {
            node {
              collection {
                slug
                catalog {
                  slug
                }
              }
              name
              slug
              node_locale
              hidden
            }
          }
        }
        dryPastaPacks: allContentfulProduct(
          filter: { productType: { slug: { eq: "pasta-pack" } } }
        ) {
          edges {
            node {
              collection {
                slug
                catalog {
                  slug
                }
              }
              name
              slug
              node_locale
              hidden
            }
          }
        }
      }
    `)

  // When viewing a product, show it in selector EVEN if it's hidden
  const filterFunction = edge => {
    if (
      currentProductSlug &&
      edge.node.node_locale === locale &&
      edge.node.slug === currentProductSlug
    ) {
      return true
    }

    return (
      edge.node.node_locale === locale &&
      !edge.node.hidden &&
      !edge.node?.collection?.some(
        col => col?.slug === 'special-items' && col?.slug === 'hidden-items',
      ) && // Hide product if in special or hidden items
      edge.node?.collection?.some(col =>
        col?.catalog?.some(cat => cat.slug === 'catalog'),
      ) // Hide product if not in catalog
    )
  }

  const pastaOptions = {
    type: 'Pasta',
    options: [
      ...dryPasta.edges.filter(filterFunction).map(edge => {
        return {
          name: edge.node.name,
          slug: edge.node.slug,
        }
      }),
      ...dryPastaPacks.edges.filter(filterFunction).map(edge => {
        return {
          name: edge.node.name,
          slug: edge.node.slug,
        }
      }),
    ],
  }

  const MacOptions = {
    type: 'Mac and Cheese',
    options: [
      ...macProducts.edges.filter(filterFunction).map(edge => {
        return {
          name: edge.node.name,
          slug: edge.node.slug,
        }
      }),
      ...macPacks.edges.filter(filterFunction).map(edge => {
        return {
          name: edge.node.name,
          slug: edge.node.slug,
        }
      }),
    ],
  }
  const macOrder = [
    'variety-pack-product',
    'cheddy-mac',
    'shella-good',
    'twist-parm',
    'mover-shaker',
    'vegan-believin',
    'down-the-hatch',
    'here-comes-truffle',
    'hey-hey-elote',
    'if-youre-hoppy-and-you-know-it',
    'smokey-dokey',
  ]
  const pastaOrder = [
    'Pasta Variety Pack',
    'loopdy-loos',
    'lucky-penne',
    'curveballs',
  ]

  MacOptions.options.sort(
    (a, b) => macOrder.indexOf(a.slug) - macOrder.indexOf(b.slug),
  )
  pastaOptions.options.sort(
    (a, b) => pastaOrder.indexOf(a.slug) - pastaOrder.indexOf(b.slug),
  )

  const allMac = [
    MacOptions.options.length > 0 && MacOptions,
    pastaOptions.options.length > 0 && pastaOptions,
  ].filter(Boolean)

  return allMac
}
