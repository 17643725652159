/** @jsx jsx */
import { jsx } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'

const PerBoxPrice = ({ price, TextComponent, styles = {} }) => (
  <Text as="p" sx={{ alignText: 'right' }}>
    <Text sx={{ ...styles }}>${price?.toFixed(2)}</Text>
    {TextComponent}
  </Text>
)

export default PerBoxPrice
