/** @jsx jsx */
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Flex, jsx, Select, Button } from 'theme-ui'
import { useLocation } from '@reach/router'
import Text from '~/components/IntlComponents/Text'

const ProductOptionSelector = ({
  title,
  type,
  variants,
  values = [],
  selectedValue = '',
  handleChange,
  isRadio,
  currentValues,
  ...props
}) => {
  const { register, getValues } = useForm()
  const { pathname } = useLocation()
  const isElote = pathname.includes('elote')
  //sort values for better presentation
  const sizeOrder = ['xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl']
  values.sort((a, b) => {
    if (type === 'gift-amount') {
      return a.name.replace(/\$/, '') - b.name.replace(/\$/, '')
    }
    if (type === 'merch-size') {
      // Sort sizes from smallest to biggest
      return sizeOrder.indexOf(a.presentation.toLowerCase()) >
        sizeOrder.indexOf(b.presentation.toLowerCase())
        ? 1
        : -1
    } else if (!isNaN(+a.presentation) && !isNaN(+b.presentation)) {
      return +a.presentation - +b.presentation
    } else {
      var nameA = a.presentation.toUpperCase() // ignore upper and lowercase
      var nameB = b.presentation.toUpperCase() // ignore upper and lowercase
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      // names must be equal
      return 0
    }
  })

  const onChange = slug => {
    const slugValue =
      slug?.target?.options?.[slug.target.selectedIndex].value || slug
    if (handleChange) {
      const option = values.filter(
        v => v.slug === (slugValue || getValues(type)),
      )
      if (option.length > 0) handleChange(option[0])
    }
  }
  const isActive = slug =>
    //filter through all values again current value. coerce empty or full arrays to true or false
    {
      return !!currentValues.filter(
        currentOptionValue => currentOptionValue.slug === slug,
      ).length
    }

  return (
    <div>
      <Text
        sx={{ color: 'secondary', marginBottom: 16 }}
        variant="text.eyebrow"
      >
        {title}
      </Text>
      <Flex
        sx={{
          width: '100%',
          '& > div': {
            width: 'inherit',
          },
          flexDirection: 'row',
          marginRight: '0.75rem',
          gap: '5px',
        }}
        {...props}
      >
        {isRadio ? (
          values.map(({ slug, presentation }) => (
            <div key={slug}>
              <Button
                type="button"
                variant={
                  isActive(slug) ? 'buttons.radio_active' : 'buttons.radio'
                }
                sx={{
                  height: '3rem',
                  minWidth: '3rem',
                  width: 'unset',
                  padding: '0 12px',
                  fontSize: '16px',
                  fontFamily: 'Sofia-Regular',
                  backgroundColor: 'override.detailBackground',
                  '&:hover': {
                    backgroundColor: isElote ? '#FFDD00' : null,
                  },
                }}
                {...register('subscription-type')}
                value={slug}
                defaultChecked={true}
                onClick={() => onChange(slug)}
              >
                {presentation}
              </Button>
            </div>
          ))
        ) : (
          <Select
            sx={{
              width: 'inherit',
              pr: '17px',
            }}
            {...register(type)}
            onChange={onChange}
            value={selectedValue}
          >
            {values.map(({ slug, presentation }) => {
              // If a variant with all the desired option values exist, it is a valid variant
              const validVariant = variants.some(variant => {
                const { optionValues } = variant
                const options = [
                  slug,
                  ...currentValues
                    .filter(value => value.option_type[0].slug !== type)
                    .map(value => value.slug),
                ]
                return options.every(slug => {
                  return optionValues.some(ov => {
                    return ov.slug === slug
                  })
                })
              })
              return (
                <option
                  disabled={!validVariant}
                  style={{
                    color: !validVariant && 'grey',
                    cursor: !validVariant ? 'not-allowed' : 'default',
                  }}
                  key={slug}
                  value={slug}
                >
                  {presentation}
                </option>
              )
            })}
          </Select>
        )}
      </Flex>
    </div>
  )
}

ProductOptionSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      presentation: PropTypes.string,
    }),
  ).isRequired,
  selectedValue: PropTypes.string.isRequired,
}

export default ProductOptionSelector
