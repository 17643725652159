/** @jsx jsx */

import React, { useEffect, useRef, useState } from 'react'
import { Flex, Heading, Text, jsx } from 'theme-ui'

export const IngredientAccordionItem = ({
  tooltipTitle,
  tooltipDescription,
  icon,
  forwardSx,
}) => {
  const [left, setLeft] = useState(0)
  const element = useRef(null)

  useEffect(() => {
    if (element.current) {
      const elementRect = element.current.getBoundingClientRect()
      const positionLeft = (window.innerWidth - 336) / 2
      const distanceFromLeft = elementRect.left - positionLeft
      setLeft(distanceFromLeft)
    }
  }, [])

  return (
    <Flex
      ref={element}
      sx={{
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        textAlign: 'center',
        '&:hover span.tooltip': { display: 'initial' },
        flex: ['1 1 80px', '0 1 96px'],
        ...forwardSx,
      }}
    >
      {icon?.svg?.content && (
        <div
          sx={{
            width: ['64px', '96px'],
            height: ['64px', '96px'],
            padding: ['6px 14px', '16px 24px'],
            display: 'flex',
            backgroundColor: 'primary',
            borderRadius: '50%',
            '& svg': {
              width: '100%',
              height: '100%',
            },
            '& path': {
              fill: 'secondary',
              stroke: 'transparent',
            },
          }}
          dangerouslySetInnerHTML={{ __html: icon?.svg?.content }}
        />
      )}

      <span>{tooltipTitle}</span>

      <span
        className="tooltip"
        sx={{
          position: 'absolute',
          display: 'none',
          zIndex: 20,
          width: '336px',
          padding: '8px',
          left: [`-${left}px`, '-35%'],
          top: '100%',
          backgroundColor: 'override.stickyATCBg',
          color: 'override.stickyATCColor',
          borderRadius: 8,
          fontSize: '16px',
          border: '2px solid',
          borderColor: 'secondary',
        }}
      >
        <Heading
          variant="text.h5"
          sx={{
            textTransform: 'capitalize',
            marginBottom: '8px',
          }}
        >
          {tooltipTitle}
        </Heading>
        <Text
          variant={'text.body'}
          sx={{ fontSize: '16px', lineHeight: '20px' }}
        >
          {tooltipDescription?.childMarkdownRemark.rawMarkdownBody}
        </Text>
      </span>
    </Flex>
  )
}
