/** @jsx jsx */
import { Flex, Box, jsx } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Text from '~/components/IntlComponents/Text'
import { fadeIn } from '~/utils/PDP'

const ProductDetailsDescription = ({
  longDescription,
  additionalProperties,
  mounted,
}) => {
  return (
    <>
      {longDescription && (
        <Text
          as={Box}
          sx={{
            opacity: mounted ? 1 : 0,
            transition: fadeIn(2500),
            maxWidth: ['100%', null, '25em'],
            '& p': {
              marginTop: '.5rem',
              marginBottom: '1.75rem',
            },
            li: {
              textAlign: 'left',
            },
            a: {
              textDecoration: 'underline',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: longDescription.childMarkdownRemark.html,
          }}
        />
      )}
      {additionalProperties && (
        <Flex
          sx={{
            gap: '1.5rem',
            width: '100%',
            maxWidth: '220px',
            justifyContent: ['center', null, 'flex-start'],
            mb: '1rem',
            alignItems: 'center',
            mx: ['auto', null, 0],
          }}
        >
          {additionalProperties.map(image => (
            <GatsbyImage
              key={image.id}
              sx={{ height: '100%', width: '100%', maxWidth: '80px' }}
              image={image.gatsbyImageData}
              alt={image.title}
            />
          ))}
        </Flex>
      )}
    </>
  )
}

export default ProductDetailsDescription
