/** @jsx jsx */

import React from 'react'
import { Grid, useThemeUI, jsx } from 'theme-ui'
import ImageAndTextSection from './ImageAndTextSection'
import { SectionDivider } from '~/components/Nav/NavMenu/SectionDivider'

export const TryNow = ({ product }) => {
  const {
    theme: { rawColors },
  } = useThemeUI()

  return (
    <>
      <div
        sx={{
          width: '100%',
          transform: ' scaleY(-1)',
          zIndex: -15,
        }}
      >
        <SectionDivider fill={rawColors.override?.sectionBackground} />
      </div>

      <div sx={{ position: 'relative', width: '100%' }}>
        {product.pedestalImage && (
          <ImageAndTextSection
            gatsbyImageData={
              product.pedestalImage
                ? product.pedestalImage.gatsbyImageData
                : null
            }
            containerStyle={{
              backgroundColor: 'secondary',
              color: 'primary',
            }}
            buttonStyle={{
              color: 'override.altButtonColor',
              backgroundColor: 'override.altButtonBackground',
            }}
            headingText={product.headingTryNow}
            subHeadingText={product.subheadingTryNow}
            cta={product.callToAction}
          />
        )}
        <Grid //needs to be grid or flex otherwise generates space for some reason
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            transform: ' scaleY(-1)',
            zIndex: 15,
          }}
        >
          <SectionDivider
            forwardSx={{ my: '-1px' }}
            fill={
              rawColors.override?.sectionBackgroundAlt ??
              rawColors.override?.sectionBackground
            }
          ></SectionDivider>
        </Grid>
      </div>
    </>
  )
}
