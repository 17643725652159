import React from 'react'
import { localeFromPathname } from '~/utils/intl/localePrefix'
import { useMacAndCheese } from './use-mac-and-cheese'

/**
 *
 * @param {{}} product
 * @returns {[*]}
 *
 * Returns an array of products choices for locale and category.
 * uses collection.hidden value to determing whether or not to display a
 * product as well as the collection slug.
 *
 *
 */
const useSelectorOptions = product => {
  const allMac = useMacAndCheese({
    locale: product.node_locale,
    currentProductSlug: product.slug,
  })

  let selectorOptions = null

  const isGoodsOrGifts = slug => {
    const transformedSlug = slug?.split('-')?.[0] || slug
    return transformedSlug === 'goods' || transformedSlug === 'gifts'
  }

  if (isGoodsOrGifts(product?.productType?.slug) || product.isGoods) {
    selectorOptions = (product.collection || [])
      .filter(
        collection => isGoodsOrGifts(collection?.slug) && !collection.hidden,
      )
      .flatMap(collection => collection?.products || [])
  } else {
    selectorOptions = allMac
  }

  return selectorOptions
}

export default useSelectorOptions
